@font-face {
    font-family: 'Myriad Pro';
    src: url('MyriadPro-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('MyriadPro-It.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('MyriadPro-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('MyriadPro-BoldIt.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

