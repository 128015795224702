$brand-color: #fc631e;
$brand-color--accessible: #c05727;
$color-black: #000;
$color-white: #fff;
$box-shadow: 0px 1px 3px #d5d4d4;
$box-shadow-safari: 0px 2px 3px #afafaf;

$border-radius: .2rem;
$border-radius-full: 100rem;

$font-size: 12px;
$font-size-desktop: 14px;

$spacing: 6px;
$spacing--02: calc($spacing / 4);
$spacing--05: calc($spacing / 2);
$spacing--2: calc($spacing * 2);

$border: 2px solid $brand-color;

:root{
    --mapPosition: 0px 0px;
    --width: 1920px;
    --height: 1080px;
}

.map {
    width: 100vw;
    height: 100vh;
    display: flex;
    box-sizing: border-box;

    .react-transform-wrapper{
        width: 100%;
        height: 100vh;

        .react-transform-component{
            width: 100%;
            height: max-content;
            display: block;
        }
    }

    &-wrapper {
        display: flex;
        width: 100%;
        height: 100%;
        position: relative;
    }

    .svg-button{
        &:hover{
            cursor: pointer;
        }
    }

    img{
        width: 100%;
    }
}

.header {
    position: absolute;
    left: 0;
    top: $spacing--2;
    z-index: 200;
    width: 100%;
    display: flex;
    justify-content: space-between;

    .navigation{
        position: relative;
        order: -1;
    }

    .zoom {
        margin-left: $spacing--2;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        gap: $spacing;

        &__btn {
            cursor: pointer;
            margin: 0;
            display: flex;
            height: 32px;
            align-items: center;
            justify-content: center;
            width: 32px;
            border: 1px solid #afafaf;
            background-color: #f6f6f6;
            box-shadow: $box-shadow;
            -webkit-box-shadow: $box-shadow-safari;
            border-radius: $border-radius;
            padding: 0;
            transition: background-color .4s ease;

            &:hover {
                background-color: #e6e6e6;

                img {
                    transform: scale(1.1);
                }
            }

            &:focus-visible {
                outline: $brand-color solid 2px;
            }

            img {
                transition: transform .2s cubic-bezier(0.18, 0.89, 0.32, 1.28);
                width: 18px;
                height: 18px;
            }
        }
    }

    .legend {
        pointer-events: none;
        position: fixed;
        left: 50%;
        bottom: calc($spacing--2 * 4);
        opacity: 0;
        transform: translateY(40px) translateX(-50%);
        transition: opacity .2s ease-in-out, transform .2s ease-in-out;
        background-color: $color-white;
        padding: $spacing $spacing--2;
        border: $border;
        border-radius: $border-radius-full;
        font-size: $font-size;
        box-shadow: $box-shadow;

        @media screen and (min-width: 576px) {
            font-size: $font-size-desktop;
            left: 0;
            bottom: 0;
            border-top-right-radius: $border-radius;
            border-bottom-right-radius: 0;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-left: 0;
            border-bottom: 0;
            padding: $spacing--2;
        }

        p {
            margin: $spacing 0;
        }

        &.--open {
            pointer-events: auto;
            opacity: 1;
            transform: translateY(0) translateX(-50%);

            @media screen and (min-width: 576px) {
                transform: translateY(0);
            }

            input {
                display: block;
            }
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            display: flex;
            gap: $spacing--2;

            @media screen and (min-width: 576px) {
                flex-direction: column;
                gap: 0;
            }

            li {
                display: flex;
                justify-content: flex-start;
                align-items: center;
            }

            span {
                display: block;
                content: '';
                width: 24px;
                margin-right: $spacing;
                border-bottom: solid 3px $brand-color;

                @media screen and (min-width: 576px) {
                    width: 96px;
                    margin-right: $spacing--2;
                }

                &.--old {
                    border-bottom: dashed 3px $brand-color;
                }
            }

            p {
                white-space: nowrap;
            }
        }
    }

    .logo {
        display: block;
        margin-right: $spacing--2;
        height: max-content;

        img {
            height: auto;
            width: 32px;
        }
    }

    .controls {
        display: flex;
        flex-direction: column;
        gap: $spacing;
    }
}

label {
    font-size: $font-size;
    font-weight: 700;
    color: $color-black;

    @media screen and (min-width: 400px) {
        font-size: $font-size-desktop;
    }
}



.radio-group {
    box-shadow: $box-shadow;
    border-radius: $border-radius-full;
    border: $border;
    background: $color-white;
    overflow: hidden;
    opacity: 0;
    padding: $spacing--02;
    transform: translateY(4px);
    transition: opacity .2s ease-in-out, transform .2s ease-in-out;

    input {
        display: none;
    }

    &.--open {
        opacity: 1;
        transform: translateY(0);

        input {
            display: block;
        }
    }

    ul {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        margin: 0;
        padding: 0;
    }

    li {
        position: relative;
        display: flex;
        align-items: center;
        list-style: none;
    }

    label {
        cursor: pointer;
        position: relative;
        z-index: 100;
        padding: $spacing $spacing--2;
    }

    .input-group {
        display: flex;
        align-items: center;
    }

    input {
        margin: 0;
        position: absolute;
        opacity: 0;

        &:checked + span {
            position: absolute;
            width: 100%;
            left: 0;
            height: 100%;
            background-color: $brand-color;
            border-radius: $border-radius-full;
            box-shadow: $box-shadow;
        }

        &:checked ~ label {
            color: $color-white;
        }

        &:focus-visible + span {
            outline: $brand-color solid 6px;
        }
    }
}

.toggle-button {
    display: flex;
    justify-content: center;

    .btn-color-mode-switch{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;

        .init-animation {
            width: 100%;
            height: 100%;
            background-color: $brand-color;
            transform: scale(0.9);
            opacity: .8;
            z-index: -1;
            position: absolute;
            border-radius: $border-radius-full;
        }
    }

    .btn-color-mode-switch > label.btn-color-mode-switch-inner{
        position: relative;
        display: block;
        cursor: pointer;
        margin: 0;
        width: 140px;
        height: 30px;
        background: $color-white;
        border-radius: $border-radius-full;
        border: $border;
        box-shadow: $box-shadow;
        overflow: hidden;
        transition: all 0.3s ease;
    }

    .btn-color-mode-switch > label.btn-color-mode-switch-inner:before{
        position: absolute;
        content: attr(data-on);
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
    }

    .btn-color-mode-switch > label.btn-color-mode-switch-inner:after{
        position: absolute;
        content: attr(data-off);
        width: 70px;
        height: 16px;
        background: $brand-color;
        color: $color-white;
        border-radius: $border-radius-full;
        left: $spacing--02;
        top: 50%;
        transform: translateY(-50%);
        transition: all 0.3s ease;
        text-align: center;
        box-shadow: $box-shadow;
        padding: 5px 0px;
    }

    .btn-color-mode-switch input[type="checkbox"] {
        opacity: 0;
        position: absolute;
    }

    .btn-color-mode-switch input[type="checkbox"]:focus-visible + label.btn-color-mode-switch-inner:after {
        outline: $brand-color solid 10px;
    }

    .btn-color-mode-switch input[type="checkbox"]:checked + label.btn-color-mode-switch-inner:after{
        content: attr(data-on);
        left: 68px;
        background: $brand-color;
        color: $color-white;
    }

    .btn-color-mode-switch input[type="checkbox"]:checked + label.btn-color-mode-switch-inner:before{
        content: attr(data-off);
        left: 20px;
    }

    .btn-color-mode-switch input[type="checkbox"]:checked ~ .alert{
        display: block;
    }
}

footer {
    position: fixed;
    bottom: $spacing--2;
    left: 50%;
    border-radius: $border-radius-full;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    gap: $spacing;
    background-color: $color-white;
    padding: $spacing $spacing--2;
    font-size: $font-size;
    border: 1px solid #afafaf;
    box-shadow: $box-shadow;
    -webkit-box-shadow: $box-shadow-safari;

    a {
        color: $color-black;
        display: block;
        text-decoration: none;

        &:hover,
        &:focus-within {
            color: $brand-color;
            text-decoration: underline;
        }
    }
}