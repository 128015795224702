svg{
    -webkit-backface-visibility: hidden;

    #Linien path {
        stroke-dasharray: 100%;
        stroke-dashoffset: 0;
    }

    #U_Changed {
        opacity: 0;

        #U1_Line,
        #U9_Line,
        #U11_Line,
        #U14_Line {
            path {
                will-change: transform, opacity;
            }
        }
    }

    #U1_2023,
    #U9_2023,
    #U_11_2023,
    #U_14_2023 {
        opacity: 0;
    }

    #U1_Line-2 path,
    #U9_Line path,
    #U11_Line path,
    #U14_Line path {
        opacity: 0;
        stroke-dasharray: 100%;
        stroke-dashoffset: 100%;
    }
}