@font-face {
    font-family: 'Univers LT Std';
    src: url('UniversLTStd-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Univers LT Std';
    src: url('UniversLTStd-BlackObl.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Univers LT Std';
    src: url('UniversLTStd-BoldObl.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Univers LT Std';
    src: url('UniversLTStd-Obl.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Univers LT Std';
    src: url('UniversLTStd-LightObl.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Univers LT Std';
    src: url('UniversLTStd.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Univers LT Std';
    src: url('UniversLTStd-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Univers LT Std';
    src: url('UniversLTStd-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Univers LT Std 45';
    src: url('UniversLTStd-Bold_1.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Univers LT Std 55';
    src: url('UniversLTStd-Black_1.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Univers LT Std 45';
    src: url('UniversLTStd-Light_1.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Univers LT Std 55';
    src: url('UniversLTStd-BlackObl_1.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Univers LT Std 45';
    src: url('UniversLTStd-LightObl_1.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Univers LT Std 45';
    src: url('UniversLTStd-BoldObl_1.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Univers LT Std 55';
    src: url('UniversLTStd-Obl_1.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Univers LT Std 55';
    src: url('UniversLTStd_1.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

